@font-face {
    font-family: "ProximaRegular";
    src: local("ProximaSoft-Regular"),
     url("../src/assets/fonts/ProximaNova-Regular.ttf") format("truetype");
    font-weight: normal;
    }

@font-face {
    font-family: "ProximaSemibold";
    src: local("ProximaSoft-Semibold"),
        url("../src/assets/fonts/ProximaNova-Semibold.ttf") format("truetype");
    font-weight: normal;
    }
body {
    font-family: "ProximaRegular";
}
.wrapper {
	padding: 24px;
}
.main-menu {
	list-style: none;
	padding: 0;
	display: flex;
}
.main-menu li   {
	text-decoration: none;
	color: #000;
	font-size: 16px;
	font-weight: 600;
	padding: 12px 16px;
	display: block;
	cursor: pointer;
}
.main-menu li:hover {
	background-color: #f5f5f5;
}
.main-menu li.active {
	background-color: #f5f5f5;
}

.line-menu {
	list-style: none;
	padding: 0;
	display: flex;
}
.line-menu li   {
	text-decoration: none;
	background-color: #ccc;
	color: #000;
	font-size: 16px;
	font-weight: 600;
	padding: 12px 16px;
	display: block;
	cursor: pointer;
}
.line-menu li:hover {
	background-color: #ececec;
}
.line-menu li.active {
	background-color: #ececec;
}

.line-filters {
	display: flex;
	align-items: center;
}

.table {
    width: 100%;
    border-spacing: inherit;
}
.table thead tr th {
    text-align: left;
    border-bottom: 1px solid #dedede;
    padding: 6px 0;
}
.table tbody tr {
    background: #fff;
    cursor: pointer;
}
.table tbody tr:hover td {
    background: #dedede;
}
.table tbody tr td {
    border-bottom: 1px solid #dedede;
    padding: 14px 6px;
}
.table tbody tr:last-child td {
    border-bottom: 3px solid #777;
}

input, select {
	padding: 12px 20px;
	margin: 8px 0;
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
  }

  button {
	width: 100%;
	background-color: #4CAF50;
	color: white;
	padding: 14px 20px;
	margin: 8px 0;
	border: none;
	border-radius: 4px;
	cursor: pointer;
  }
  
  button:hover {
	background-color: #45a049;
  }

  .mr-10 {
	margin-right: 10px;
  }
  .mr-20 {
	margin-right: 20px;
  }

  .line {
	position: relative;
}
.span-line {
	position: absolute;
	height: 20px;
	background: #ccc;
	margin-bottom: 3px;
}
.span-line-label {
	position: absolute;
	width: fit-content;
	min-width: 350px;
	left: 3px;
}

.dot {
	width: 15px;
	height: 15px;
	background-color: #ccc;
	border-radius: 50%;
}
.dot.yes {
	background-color: #45a049;
}
.dot.no {
	background-color: #cf450e;
}

.pressure-row {
	position: relative;
	background-color: green;
	height: 20px;
	margin-bottom: 6px;
}